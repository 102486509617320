.portfolio {
    display: flex;
    flex-direction: column;
    background: white;

}

.portfolio-spinner {
  height: 150px;
}


.portfolio h1 {
  font-size: 4em;
  font-weight: 500;
  font-family: Monserrat;
  text-align: center;
}

.properties {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 85px 5% 30px 5%;

}

.property {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 25px 0;
    box-shadow: 0 0 7px #333;
    max-width: 2000px;
    transition: transform .2s; /* Animation */
    cursor: pointer !important;
}

.property:hover {
  transform: scale(1.005); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.property-img {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: auto;
}


.property-img img {
  max-width: 100%;
  max-height: 100%;
  
  width: 100%;
  height: auto;
  object-fit: cover;
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 1;

}


.property-text{
    background: #F8F8F8;
    padding: 20px 20px;
    width: 60%;
    color: black;
    flex-grow: 2;
    flex-shrink: 2;
}

.property-text h1{
  font-family: Copperplate-light;
  font-size: 2.3em; 
  font-weight: 500; 
  text-align: center;
  margin: 0;
  padding: 0 0 0px 0;
}

.property-text h3{
  font-size: 1em; 
  font-weight: 600; 
  text-align: center;
  margin: 0;
  padding: 0 0 20px 0;
  color: gray;
}

.property-text p{
  text-align: left;
}

.neighborhood-section {
  margin: 0 0 50px 0;
}

.neighborhood-section  {
  margin: 0 0px 60px 0px;
}

#neighborhood-title {
  color: #4ba9c8;
  font-family: Copperplate;
  text-align: left;
  font-weight: 500;
  border-bottom: 1px solid gray;
  margin-bottom: 27px;
  text-shadow: 1px 1px black;
  font-size: 4em !important;
}




@media (max-width: 950px) {
  .property-text {
    width: 100%;
  }
  .property-img {
    width: 100%;
  }
  #neighborhood-title {
    text-align: center;
    font-size: 0.9em;
    font-size: 3.5em !important;
  }
  .properties {
    margin-top: 50px;
    margin-bottom: 0px;
  }
}

  