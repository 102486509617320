
.about-banner {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}


.about-items {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-self: center;
    text-align: center;
}

.aboutItem {
    flex-basis: 300px;
    flex-grow: 1;
    padding: 20px 25px;
}


.aboutItem h3 {
    text-align: left;
    font-size: 2.9em !important;
    border-bottom: 1px solid black;
    text-align: left;
}


.aboutItem {
    font-size: 1em;
    text-align: center;
    font-family: Monserrat;
    color: whitesmoke;
}

.link {
    padding: 5px 0 0 0;
    font-size: 1.2em;
    color: rgb(20, 82, 207);
    text-decoration: none;
    font-family: Monserrat;
}

.link :hover {
    color: purple;
    text-decoration: none;
  }
.link :active {
    color: purple;
    text-decoration: none;
  }


.about-banner-bottom {
    font-weight: 700;
    margin: 50px 20px 100px 20px;
}

.about-banner-bottom h3 {
    color: #4ba9c8 !important;
    text-shadow: none;
    font-size: 2.4em;
    font-weight: 700;
}

/* text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black !important;
 */


.about-banner-bottom p {
    color: black !important;
    text-shadow: none;
    font-weight: 400;
    font-size: 1.4em;

}




#strategy-link {
    width: 100px;
    margin: auto;
    text-decoration: none;
    text-shadow: none;
    position: relative;
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: color 0.1s, background-color 0.1s;
    color: black;
  }
  #strategy-link:hover {
    color: #4ba9c8;
    text-decoration: none;
  }
  #strategy-link:focus, #strategy-link:active {
    color: goldenrod;
    text-decoration: none;

  }
  
  #strategy-link::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    height: 3px;
    width: 100%;
    background-color: black;
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  }
  
  #strategy-link:active::before {
    background-color: black;
  }
  
  #strategy-link:hover::before,
  #strategy-link:focus::before {
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }



  @media (max-width: 730px) {
    .aboutItem {
        flex-basis: 100%;
    }
  }


  @media (max-width: 1200px) {
    .aboutItem {
        flex-basis: 100%;
        padding: 50px 25px;
    }
    .about-banner-bottom h3{
        text-align: center !important;
    }
  }

  @media (max-width: 500px) {
    .aboutItem {
        padding: 35px 20px;
    }
  }


