
.investment-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center; 
    padding: 0 0 0px 0;
}

.investment-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 10%;
    font-size: 1em;
}

.investment-intro p {
    font-family: Copperplate;
    font-weight: 300;
    text-align: center;
    padding: 5px 50px;
    font-size: 1.4em;
}

.investment-intro li {
    font-size: 1.1em;
    margin: 0 0 5px 0;
    padding: 5px;
}

.investment-query-bar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background: #4ba9c8;
    padding: 10px 0 10px 55px;
    margin: 30px 0 0px 0;
    align-self: flex-start;
}

.investment-query-bar h1{
    margin: 10px;
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: Copperplate;
    color: #c0c0c0;
    font-weight: 600;
    cursor: pointer;
}


.investments-grid {
    width: 100%;
    margin: 0px 50px;
    padding: 30px 50px;
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.5rem;
}

.investments-grid a{
    width: 100%;
    height: 100%;
}


 .investment{
    width: 100%;
    object-fit: cover;
} 
  
  
  
  .investments-grid img {
    display: flex;
    flex-basis: 50%;
    height: 250px !important;
    width: 100%;
    object-fit: cover;
    padding: 5px;
    border: 1px solid gainsboro;
  }
 
  
  .investments-grid img:hover {
      box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }

  .results-spinner {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: center;
    align-items: center;
    background: #323232;
}
  



    @media (max-width: 400px){
        /* .investment {
          flex-basis: 100%;
        } */
      }

    
    @media (max-width: 600px){
        .investment-intro p, li {
            font-size: 1.2em !important;
        }
    }
    
    
      @media (min-width: 1200px){
        /* .investment {
          flex-basis: 33%;
        } */
        /* .investments-grid {
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        } */

      }
    
      @media (min-width: 1500px){
       /*  .investment {
          flex-basis: 25%;
        } */
      }
    
      @media (min-width: 2000px){
        /* .investments-grid div {
    
        } */
      }
    