.featured-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 20px 20px;
}

.featured-container h2 {
    margin: 0;
    padding-bottom: 0px;
    font-size: 2.8em;
}



.featured-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 0 0;
}

.featured-item {
    flex-basis: 400px;
    padding: 10px 10px;
    position: relative;

    transition: transform .2s; /* Animation */
}

.featured-item:hover {
    transform: scale(1.01);
}

.featured-item img {
    box-shadow: 0 0 7px #333;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.middle div {
    background-color: white;
    color: black;
    padding: 16px 32px;
    text-align: center;
}

.prop-name {
    font-family: Monserrat;
    font-size: 1.5em;
    font-weight: 500;
}

.prop-location {
    font-size: 1em;
    color: #585858;
}


.featured-item:hover img {
    opacity: 0.3;
  }
  
  .featured-item:hover .middle {
    opacity: 1;
  }
  


.text {
    text-align: left;
}

.link {
    color: rgb(20, 82, 207);
    text-decoration: none;
}

.link:hover {
    color: goldenrod;
    text-decoration: none;
  }
.link:active {
    color: goldenrod;
    text-decoration: none;
  }



@media (max-width: 750px) {
    .featured-items {
        flex-wrap: wrap;
    }

    
}
