

#lease-terms {
    margin: 15px 0 15px 0;
}

#lease-terms p{
    margin-bottom: 0;
    margin-top: 0;
}

#lease-terms p:nth-child(1){
    margin: 30px 0 5px 10px;
    font-weight: 600;
}



.property-container {
    width: 100%;
    margin: 100px 0 0 0;
    background: #F0F0F0;
}


.property-banner-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 30px;
    cursor: pointer;

}

.property-spinner {
    height: 200px !important;
}

.property-banner-overlay h1 {
    font-size: 6.5em;
    font-weight: 600;
    color: #4ba9c8;
    font-family: Copperplate;
    text-shadow: 2px 2px black;
    margin: 0 0 0 0;
}

.property-banner-overlay h3 {
    font-size: 2.8em;
    font-weight: 400;
    color: whitesmoke;
    font-family: Copperplate-light;
    text-shadow: 1px 1px black;
    border-bottom: 1px solid gainsboro;
    padding: 0 30px;
    margin: 0 0 50px 0;
}

.property-banner-overlay p {
    font-size: 2.3em;
    color: white;
    font-family: Copperplate-light;
}

.property-banner:hover .property-banner-overlay {
    display: flex;
}

.property-info-wrapper {
    display: flex;
    flex-direction: row;
    margin: 25px 5% 25px 5%;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.2em;
}

#property-description {
    flex-basis: 100%;
    padding: 25px;
    font-size: 1.1em;
}

#property-amenities {
    flex-basis: 100%;
    padding: 20px;
}


.property-box {
    font-size: 1.1em;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 3% 0px 3%;
    margin: 0 0 0 0;
}



.property-box-lists {
    margin: 10px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.property-box-list {
    flex-basis: 50%;
    list-style: square;
}

.property-box-list:nth-child(1){ 
    padding-left: 20px;
    padding-right: 20px;
}

.property-box-list:nth-child(2){ 
    padding-left: 40px;
    padding-right: 5px;
}

.property-box-list p{
    text-align: left;
    font-size: 1.4em !important;
    margin: 0 0 5px 0;
    font-family: Copperplate-light;
}

.property-box-list li{
    font-size: 1.1em !important;
    margin: 15px 0;
}


.property-carousel {
    width: 100%;
    background: slategray;
}

.property-box p {
    font-size: 1.2em;
}

.property-box-text {
    flex-basis: 60%;
    flex: 1;
    margin: 0 0 0 0;
    font-size: 1.1em;
    background: seashell;
    padding: 10px 10px 10px 10px;
}





@media (max-width: 1000px) {
    .property-carousel {
        flex-basis: 85%;
    }
    .property-box-text {
        flex-basis: 100%;
    }
}

@media (max-width: 1500px) {
    .property-info-wrapper {
        flex-wrap: wrap;
    }
}


@media (max-width: 1000px) {
    .property-banner-overlay {
        font-size: 0.8em;
    }
  }
  

@media (max-width: 550px) {
    .property-banner-overlay {
        font-size: 0.6em;
    }
  }
  

  @media (max-width: 375px) {
    .property-banner-overlay {
        font-size: 0.5em;
    }
  }
  
