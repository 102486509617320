.stats-container {
    display: flex;
    flex-direction: column;
    padding: 50px 20px 25px 20px;
    justify-content: center;
    align-items: center;
}

.stats-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 25px 10px 10px 10px;
    justify-self: center;
    align-self: center;
    width: 100%;
   
  }

.stats-grid-item  {
    padding: 10px;
    text-align: center;
    justify-self: center;
    max-width: 200px;
}

.stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0 0 0;
}

.stats-title {
    text-align: center;
    text-shadow: -1px -1px 0 black, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-size: 5em;
    margin: 0;
    font-family: Copperplate;
    color: goldenrod;
}

.stat {
    display: flex;
    flex-direction: column;
    height: 350px !important;
    flex-basis: 25%;
    justify-content: flex-start;
    padding: 20px;
}

.stat-text {
    text-align: center; 
    color: whitesmoke;
}

.stat-text p:nth-child(1) {
    font-family: Copperplate;
    font-size: 2.5em;
    margin: 0;
    
}

.stat-text p:nth-child(2) {
    font-family: Copperplate-light;
    font-size: 1.2em;
    padding: 0 5%;
}


.stat-icon {
    margin: 0 auto 10px auto;
    padding:30px;
    border-radius: 50%;
    max-width: 125px;
    background: gainsboro;
    color: black;
}

#stats-button {
    background-color: #4ba9c8; /* Green */
    border: none;
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    font-size: 1em;
    font-family: Copperplate-light;
    transition: transform .2s; /* Animation */
    box-shadow: 0 0 3px #333;
    width: 150px;
    display: inline-block;
    justify-self: center;
    align-content: center;
    align-self: center;
    margin: 15px 0 !important;
}



#stats-button:hover {
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    color: white;
}

#stats-button:active {
    color: goldenrod;
}




@media (max-width: 975px){
    .stat{
        flex-basis: 50%;
    }
    .stats-grid {
        grid-template-columns: auto auto auto;
    }
}

@media (max-width: 650px){
    .stat{
        flex-basis: 100%;
        justify-content: space-evenly;
    }
    .stats-grid {
        grid-template-columns: auto auto;
    }

    .stats-title {
        font-size: 3.2em;
    }

    .stat-text {
        font-size: 0.9em; 
    }
}

@media (max-width: 500px){
    .stats-grid {
        grid-template-columns: auto;
    }
}