
.investor-info {
    /* background-color: #F0F0F0; */
    margin-top: 25px;
    width: 100%;
    border-top: 1px solid #E8E8E8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 1% 0 1%;
    word-wrap:break-word
}

 
#hudson-county-img {
    height: 100px;
    width: 50px;
    object-fit: cover;
    z-index: 0;   
}



.investor-info-slide {
    /* display: flex;
    flex-direction: column; */
    margin: 20px 5% 15px 5%;
    padding-bottom: 50px;
    text-align: left;
    border-bottom: 1px solid #E8E8E8;
}

.investor-info-slide>p,span {
    font-size: 1.2em;
    line-height: 23px;
}




#right-floated {
    float: right;
    width: 300px;
    height: auto;
    margin-left: 5px;
}


#hudson-county {
    float: right;
    width: 300px;
    height: auto;
    margin-left: 5px;
}


#gold-coast-info>span {
    /* margin: 0 10%; */
    font-size: 1.2em;
}


#left-floated{
    float: left;
    width: 120px;
    height: auto;
    margin-right: 20px;
    margin-bottom: 50px;
}


.points-of-interest {
    display: flex;
    flex-direction: column;
    margin: 25px 0 0 0;
}

.points-of-interest>h4 {
    font-style: italic;
    margin-bottom: 5px;
    font-size: 1.2em;
}

.points-of-interest>p {
    margin: 0;
    padding: 5px 10px;
    font-size: 1.1em;
}


.points-of-interest>p:nth-child(odd) {
    background-color: #80aaff;
}

.points-of-interest>p:nth-child(even) {
    background-color: #ccddff;
}



#west-ny-demographics {
    float: right;
    width: 400px;
    height: auto;
    margin-left: 15px;
}

#west-ny-home-ownership {
    float: right;
    width: 400px;
    height: auto;
    margin-left: 15px;
}


.employers-slide {
    margin: 50px 5% 50px 5%;
    text-align: left;
}

.employers-lists {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
}

.employers-lists>ul {
    list-style-type: square;
    font-size: 1.2em;
    padding-left: 25px;
    padding-right: 50px;
}

.employers-lists>ul>li {
    margin: 0 0 5px 0;
}


.transport-section {
    margin: 50px 20% 50px 5%;
    background-color: white;
    text-align: left;
}

.transport-section>p {
    font-size: 1.2em;
    margin: 0;
}

#pdf-section-header {
    font-family: Copperplate;
    font-size: 2.8em;
    color: #4ba9c8
}

.transport-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 10px 0 0;
}


.transport-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 35px 10px 0 0;
    margin-bottom: 5px;
}

.transport-header>h3 {
    font-size: 2em;
    margin: 0 0 0 0;
    text-decoration: underline;
    color: #4ba9c8;
    font-family: Copperplate;
    font-weight: 500;
}


.transport-icons{
    color: #4ba9c8;
    margin-right: 9px;
    margin-bottom: 7px;
    background-color: black;
    border-radius: 5px;
    padding: 8px 8px;
}

.transport-text {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
}

.transport-text>p {
    font-size: 1.2em;
}

.transport-text>ul {
    list-style-type: square;
    padding-left: 20px;
    margin: 0;
}

.transport-text>ul>li {
    margin: 0 0 5px 0;
    font-size: 1.2em;
}



#market-report-avg-rent {
    margin-top: 25px;
    background-color: seashell;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 10px 10px;
}

#market-report-avg-rent>p {
    font-size: 1.7em;
}



@media(max-width: 500px) {
    #west-ny-demographics {
        width: 300px
    }
    
    #west-ny-home-ownership {
        width: 300px
    }

}



@media(max-width: 600px) {
    .investor-info-slide p,span,ul>li {
        font-size: 1em;
    }

    /* .transport-header>h3 {
        font-size: 1.6em;
    } */

    #gold-coast-info>span {
        /* margin: 0 10%; */
        font-size: 1em;
    }

    .employers-lists {
        flex-wrap: wrap;
    }

    .employers-lists>ul {
        margin: 0 0 0 0;
    }

    .employers-lists>ul>li {
        margin: 0 0 5px 0;
        font-size: 0.8em !important;
    }

    .investor-info {
        
    }
    
    #pdf-section-header {
        font-size: 2.2em;
    }
}


@media(max-width: 800px) {
    #pdf-section-header {
        text-align: center;
    }

    #west-ny-demographics {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    
    #west-ny-home-ownership {
        float: none;
        display: block;
        float: none;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    #hudson-county {
        float: none;
        display: block;
        float: none;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}
