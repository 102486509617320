.home-banner-container {
    width: 100%;
    height: 100vh;
    
    /* Add this */
    top:0;
    left:0;
    z-index: 0;
}


.slide-container {
    height: 77vh;
}

.carousel-img {
    height: 100%;
    width: auto;
    object-fit: cover;
    z-index: 0;
}

.slide-text-left{
    left: 0;
    top: 0;
    margin: 100px 0 0 0;
    box-sizing: content-box;
    height: calc(100% - 100px);
    width: 55%;
    position: absolute;
    background: rgba(46, 126, 152, 0.6);
    text-align: center;
}


.slide-text-right{
    top: 0;
    right: 0;
    margin: 100px 0 0 0;
    box-sizing: content-box;
    height: calc(100% - 100px);
    width: 55%;
    position: absolute;
    background: rgba(46, 126, 152, 0.6);
    text-align: center;
}


.text-overlay {
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 10px;
    /*background: rgba(0, 0, 0, 0.24);*/
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.text-overlay h1 {
    padding: 20px 0 0 0;
    font-size: 6em;
     margin: 0 0;
     color: wheat;
     font-family: Copperplate;
     text-shadow: -1px -1px 0 black, 2px -1px 0 black, -1px 1px 0 black, 2px 2px 0 black;
}


.text-overlay p:nth-child(1) {
    font-size: 2.2em;
    margin: 0 0;
    color: white;
    font-family: Copperplate-light;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.text-overlay p:nth-child(2) {
    font-size: 2.6em;
    margin: 0 0;
    color: white;
    font-family: Copperplate-light;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.tag-line{
    font-family: Archivo;
    margin: 0 0 20px 0;
    position: relative;
    bottom: 0;
}

.tag-line p:nth-child(1){
    font-size: 3em;
    color: white;
    font-family: Copperplate-light;
    text-shadow: none;
}

.tag-line p:nth-child(2){
    font-size: 2em;
    margin: 0 0;
    color: white;
    font-family: Copperplate-light;
    text-shadow: none;
}




.welcome-top {
    text-align: center;
    height: 23vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(241, 241, 241, 0.9); /* lighter: 208, 194, 191     darker: 161, 133, 128*/
    width: 100%;
}

.welcome-top p {
    top: 50%;
    font-size: 1.5em;
    text-align: center;
    color: black;
    font-family: Copperplate-light;
    margin: 10px 25px;
}


.welcome-bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white; /* lighter: 208, 194, 191     darker: 161, 133, 128*/
    text-align: center;

}

.welcome-bottom p {
    font-size: 1.5em;
    color: black;
    font-family: Copperplate-light;
    margin: 10px 25px;
    text-align: left;
}



.learn-more_button {
    background-color: #4ba9c8; /* Green */
    border: none;
    color: white;
    margin: 2px 0 5px 0;
    padding: 15px 25px;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    font-family: Copperplate-light;
    transition: transform .2s; /* Animation */
    box-shadow: 0 0 3px #333;
    
}

.learn-more_button:hover {
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    color: white;
}

.learn-more_button:active {
    color: goldenrod;
}





@media (max-width: 615px) {
    .text-overlay h1 {
        font-size: 4.5em;
    }

    .text-overlay p:nth-child(2) {
        font-size: 1.5em;
    }
}


@media (max-width: 800px){
    .slide-text-left{
        width: 100%;
    }
    .slide-text-right{
        width: 100%;
    }

}


@media (max-width: 1112px){
    .text-overlay h1 { 
        font-size: 5em;
    }
}



@media (max-width: 1080px){
    .welcome-bottom {
        display: initial;
    }
    .welcome-top {
        display: none;
    }

    .text-overlay p:nth-child(2) {
        font-size: 2em;
    }

    .slide-container {
        height: 100vh;
    }


    .tag-line p:nth-child(1){
        font-size: 2.5em;
        
    }

    .tag-line p:nth-child(2){
        font-size: 2em;
    }

}

@media (min-width: 1081px){
    .welcome-top {
        display: initial;
    }
    .welcome-bottom {
        display: none;
    }
}



@media (min-width: 2000px){
    .welcome-top {
        height: 15vh;
    }
    .welcome-top p {
        margin-bottom: 5px;
    }
    #welcome-button {
        padding: 7px 15px
    }
}


@media (max-height: 600px){
    .welcome-top{
        display: none;
    }
    .welcome-bottom{
        display: initial;
    }
    .slide-container{
        height: 100vh;
    }
}


@media (max-width: 450px){
    .tag-line p:nth-child(1){
        font-size: 2em;
    }

    .tag-line p:nth-child(2){
        font-size: 1.5em;
    }

    .text-overlay h1 {
        padding: 20px 0 0 0;
            font-size: 4.5em;
    }

    .text-overlay p:nth-child(2) {
        font-size: 1.8em;
       
    }

}


