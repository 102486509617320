
.careers-container {
    width: 100%;
    margin: 100px 0 0 0;
    background: #F0F0F0;
}


.careers-banner-overlay {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 30px;
}

.careers-banner-overlay h1 {
    font-size: 6.5em;
    font-weight: 600;
    color: #ff4c4c;
    font-family: Copperplate;
    text-shadow: 2px 2px black;
    margin: 0 0 0 0;
}

.careers-banner-overlay h3 {
    font-size: 2.8em;
    font-weight: 400;
    color: whitesmoke;
    font-family: Copperplate-light;
    text-shadow: 1px 1px black;
    border-bottom: 1px solid gainsboro;
    padding: 0 30px;
    margin: 0 0 50px 0;
}

.careers-banner-overlay p {
    font-size: 2.3em;
    color: white;
    font-family: Copperplate-light;
}

.careers-banner:hover .careers-banner-overlay {
    display: flex;
}