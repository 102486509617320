
.case-study-body{
    width: 100%;
    margin: 100px 0 0 0;
    padding: 15px 5% 75px 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.1em;
}

.case-study-title {
    margin: 0 0 50px 0;
    text-align: left;
}

.case-study-title h1{
    color: 	#A9A9A9;
    margin: 0 0 5px 0;
    font-size: 1.8em;
    text-align: left;

}

.case-study-title h2{
    margin: 0 0 5px 0;
    font-size: 2em;
    text-align: left;
    font-weight: bold;

}

.case-study-title h3{
    margin: 0;
    font-size: 0.9em;
    text-align: left;
}

.case-study-body h3{
    margin: 0;
    font-size: 0.9em;
    text-align: left;
}


.case-study-data {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 50%;
}

.case-study-data-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 !important;
    margin: 20px 20px 0 0;
}

.case-study-data-wrapper div:nth-of-type(1) {
    margin: 0 20px 0 0;
}


#case-study-spinner {
    margin-top: 100px;
    height: 700px !important;
}


.case-study-data-label{
    color: 	#696969;
    text-transform: uppercase;
    font-family: Copperplate-light;
    font-weight: bold;
    margin: 0 !important;
}

.case-study-data-row div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 0 50px;
    flex-basis: 70%;
    padding: 0 !important;
}




.case-study-images {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 10px;
}

.case-study-thumbnail {
    display: flex;
    object-fit: cover;
    align-items: flex-start;
    margin: 0 0 0 0;
}

.case-study-thumbnail:nth-child(1){
    margin: 50px 0 15px 0;
}

.case-study-thumbnail img {
    width: 100%;
    height: auto;
}


.investment-highlights {
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    justify-content: flex-start;
    margin: 50px 0 0 0;
}

#data-title {
    color: 	#696969;
    text-transform: uppercase;
    font-family: Copperplate-light;
    font-weight: 600;
    margin: 0 0 15px 0;
    text-decoration: underline;
    font-size: 1.1em;
    color: black;
}


.investment-highlights ul {
    padding: 0 0 0 20px;
}


.investment-highlights li {
    padding: 0 0 3px 0;
}





.amenities{
    margin: 0 0;
}




@media (max-width: 900px){
    
    .case-study-data {
        flex-basis: 100%;
    }

    .case-study-images {
        flex-basis: 100%;
    }
}

@media (max-width: 700px){
    .case-study-body{
        font-size: 0.9em !important;
    }
}

@media (max-width: 500px){
    .case-study-body{
        font-size: 0.9em !important;
    }

    .case-study-data-row{
        justify-content: center !important;
        margin: 20px 5px 0 0;
    }

    .investment-highlights{
        margin: 80px 0 0 0;
    }


    .case-study-data-row div{
        padding: 0 10px 0 0;
        flex-basis: 80%;
        margin: 0 0 0 20px;

    }
    .case-study-data-row {
        padding: 5px 0;
    }   
    
    .case-study-thumbnail:nth-child(1){
        margin: 15px 0 15px 0;
    }
    

} 






