.strategy-container{
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    background: white;
    margin: 0 0;
}



.strategy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
}

.strategy-box-section {
    margin: 0px 0 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
}

.strategy-summary {
    margin: 0;
    padding: 50px 10% 0px 10%;
    max-width: 1500px;
    font-size: 1.5em;
    font-family: Copperplate-light;
    max-width: 1500px;
    text-align: center;
}


.strategy-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0px 10px 0px 10px;
    flex-wrap: wrap;

}

.strategy-box{
    flex-basis: 30%;
    margin: 10px;
    padding: 50px 25px;
    background: rgba(75,169,200, 1);
}

.strategy-box h2 {
    font-family: Copperplate;
    font-size: 2.9em;
    color: whitesmoke
}

.strategy-box p {
    color: black;
    font-size: 1.3em;
    font-weight: 500;
    font-family: Monserrat;
}

.value-add-link {
    color: white;
    font-size: 1.3em;
    font-weight: 500;
    font-family: Monserrat;
}

.value-add-link:hover{
    color: blueviolet; 
}

.value-add-link:active{
    color: goldenrod; 
}


.strategy h1 {
    text-align: center;
    font-family: Copperplate;
    font-size: 4em;
}

/*
    text-shadow: -1px -1px 0 black, 2px -1px 0 black, -1px 1px 0 black, 2px 2px 0 black;

*/




.location-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0% 0px 0%;
    width: 100%;
    background: white;
    margin-bottom: 50px;
}

.location-flex-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 50px;
}

.location-flex-box .location-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px 0 0;
  flex: 0 0 60%;
}


.location-text h3 {
  text-align: left;
}


.location-flex-box .location-image{
  flex: 0 0 40%;
}

.location-flex-box .location-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.location-data p {
    font-size: 1.3em; 
}

.location-data h1 {
    text-align: center;
    font-family: Copperplate;
    font-size: 4em;
}

.location-data h3 {
    margin: 0;
    font-weight: 900;
    font-size: 1.3em;
}







.value-add-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 25px 0;
  width: 100%;
  /* background: #F0F0F0; */
}

.value-add-section p {
  margin: 0;
}

.value-add-section h2 {
  font-size: 2.5em;
}


#value-add-table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 50px 0;
  }
  
  #value-add-table td, #value-add-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #value-add-table tr:nth-child(even){background-color: #f2f2f2;}
  
  /*#value-add-table tr:hover {background-color: #ddd;}*/
  
  #value-add-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4ba9c8;
    color: black;
  }

  #value-add-table tr td:nth-child(2){
    background-color: #F5F5F5;
    width: 50%; 
  }

  #value-add-table tr td:nth-child(1){
    background-color: white; 
  }

  #value-add-table td{
    font-size: 1.1em;
  }


  .value-add-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0 0 50px 0;
    background-repeat: space;
    max-width: 1800px;
  }

  .value-add-image {
    display: flex;
    flex-direction: row;
    flex-basis: 40%;
  }

  .value-add-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .value-add-item:nth-of-type(2) .value-add-image {
    margin: 0 0 0 10px;
  }
  .value-add-item:nth-of-type(2) .value-add-grid {
  margin: 0 10px 0 0;
  }
  
  .value-add-item:nth-of-type(3) .value-add-image {
      margin: 0 10px 0 0;
  }
  .value-add-item:nth-of-type(3) .value-add-grid {
    margin: 0 0 0 10px;
    }

    .value-add-item:nth-of-type(4) .value-add-image {
      margin: 0 0 0 10px;
  }
  .value-add-item:nth-of-type(4) .value-add-grid {
  margin: 0 10px 0 0;
  }


  .value-add-grid {
    padding: 0 50px 20px 50px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    max-width: 1000px;
    flex-basis: 40%;
  }


  .value-add-item:nth-of-type(2) .value-add-grid {
    background: #7ba3cd;
  }
  .value-add-item:nth-of-type(3) .value-add-grid {
    background: #89a28f;
  }
  .value-add-item:nth-of-type(4) .value-add-grid {
    background: #4badc0;
  }



  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px 0;
  }


  /* grid row border lines */
  .row:not(:first-child) {
    border-bottom: 1px solid;
    border-color: #666666;
  }


  .row:nth-child(1) {
      margin: 15px 0 0 0;
      color: black;
      font-size: 1.2em;
      margin-bottom: 10px;      
  }

  .row:nth-of-type(n + 3) {
    
  }

  .row-title {
    border-bottom: 1px solid;
  }


  .row-line {
    border-top: 1px solid red;
    height: 1px;
    color: black
  }

  


  .cell {
      margin: auto;
      flex-basis: 40%;
      padding: 10px;
      font-size: 1.4em;
      text-align: center;
      font-family: Copperplate-light;
      display: flex;
      flex-direction: column;
      flex-shrink:0
  }

   .arrow {
    margin: auto;
    flex-basis: 10%;
    padding: 10px;
    font-size: 1.5em;
    text-align: center;
    font-family: Copperplate-light;
  }

  .cell p {
      font-weight: 500;
      color: black;
  }

  .cell p:nth-child(2) {
    margin: 5px 0 0 0;
  }

 
  /*text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;*/

  .row:nth-child(2) h3{
    font-size: 2.8em !important;
    font-weight: 600;
    color: black;
    font-family: Copperplate;
    margin: auto;
  }


  /* middle column arrow */
  .row div:nth-child(2){
    color: whitesmoke;
  }


  .value-add-title {
    margin: 100px 0 !important;
}



.case-studies-section {
    /* background: #F0F0F0; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding: 0 20px 20px;
}


.case-studies-grid-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 10px;
}

#case-studies-grid {
  width: 100%;
  margin: 0px 50px;
  padding: 50px 20px 75px 20px;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: auto;
  grid-gap: 1rem;
  object-fit: cover;

}

#case-studies-grid a{
  width: 100%;
  height: 100%;
}


  
  
#case-studies-grid img {
    display: flex;
    flex-basis: 50%;
    height: 250px !important;
    width: 100%;
    object-fit: cover;
    padding: 5px;
    border: 1px solid gainsboro;
}

.small-spinner {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: center;
    align-items: center;
}
 



/* .case-studies-grid::after {
  content: "";
  flex: auto;
} */


.case-studies {
  flex-basis: 33%;
  padding: 10px 10px;
  flex-shrink: initial;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  margin: 0 0 15px 0;
}


.case-study-preview-title {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px;
}

.case-study-preview-title  h2 {
  font-family: Copperplate-light;
  text-align: center;
  margin: 0;
  font-weight: 300;

}

.case-study-preview-title  h3 {
  font-family: Copperplate-light;
  text-align: center;
  margin: 0;
  font-size: 1em;
  color: #696969;
}


.case-studies-grid img {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 5px; /* Some padding */
    width:100%;
    object-fit:cover;
}

.case-studies-grid img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }


  @media (min-width: 800px){
    .case-studies-grid div {
      flex-basis: 33%;
    }
  }


  @media (max-width: 800px){
    .case-studies-grid div {
      flex-basis: 50%;
    }
    .value-add-section{
      padding: 0 0;
    }
    .value-add-grid{
      padding: 5%;
      font-size: 0.8em;
    }
    .location-flex-box .location-data{
      padding: 0 0;
    }
    .location-flex-box {
      margin: 0 20px;
    }
  }


  @media (max-width: 425px){
    .case-studies-grid div {
      flex-basis: 100%;
    }
    .value-add-grid {
      font-size: 0.5em;
      margin: 0;
    }

    .value-add-title {
      margin: 0;
      padding: 0;
    }
    .cell {
      font-size: 1.7em;
      padding: 2px;
    }

    .row:nth-of-type(n + 3) {
      padding: 20px 0 !important;
    }
  }


  @media (min-width: 1200px){
    .case-studies-grid div {
      flex-basis: 33%;
    }
  }

  @media (min-width: 1500px){
    .case-studies-grid div {
      flex-basis: 33%;
    }
  }

  @media (max-width: 1500px) {
    .value-add-image {
      display: none;
    }

    .value-add-grid {
      flex-basis: 100%;
      margin: 0 !important;
    }

    .value-add-item:nth-of-type(2) {
      background-image: url(/backgrounds/jersey_city3.jpg);
    }

  
    .value-add-item:nth-of-type(3) {
      background-image: url(/backgrounds/jersey_city.jpg);
    } 


    .value-add-item:nth-of-type(4) {
      background-image: url(/backgrounds/jersey_city2.jpg);
    }

  }



  @media (min-width: 2000px){
    .case-studies-grid div {

    }
  }


@media(max-width: 1000px){
    .strategy-box{
        flex-basis: 100%;
    }
    .location-flex-box .location-data{
      flex: 0 0 100%;
    }

    .location-flex-box .location-image{
      display: none;
    }
}