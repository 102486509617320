.about-main {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    background: white;
}


.down-icon {
    color: white;
}




.about-columns-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 75px 0 0 0;
}


.about-columns {
    margin: 0 50px 50px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.about-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 25px 75px 25px;
    flex-basis: 30%;
    max-width: 1000px;
}

.about-column div {
    width: 100%;
    margin: 0 0 10px 0;
    position: relative;
    padding-left: 1em;
  }

.about-column div p {
    font-size: 1.3em;
} 
  
.about-column div span {
    position: absolute;
    left: 0;
    top: 1px;
  }

.about-column h1 {
    font-size: 2.5em;
    font-family: Copperplate;
}

.about-column-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* flex-end*/
    text-align: center;
    flex-basis: 100px !important;
    margin: 0 0 10px 0 !important;
    padding: 10px !important;
}

/*     flex-basis: 200px !important;
*/


.leader-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px 50px 20px;
    flex-direction: column;
    align-items: center;
}

.leaders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 0;
}

.leader-section h1{
    text-align: center;
    font-family: Copperplate;
    font-size: 4em;
}

.leader{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 50px;
    object-fit: cover;
}


.leader h2 {
    font-family: Copperplate;
    margin: 10px 0 5px 0;
    font-size: 2.7em;
    color: #4ba9c8;

}

.leader h3 {
    font-family: Copperplate-light;
    margin: 0;
    font-size: 1.4em;

}

.leader img {
    border-radius: 25%;
}


#sanjay {
    height: 400px
}

#sam {
    height: 400px
}


#team-member-button {
    background-color: #4ba9c8; /* Green */
    border: none;
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    display: inline-block;
    font-size: 1.3em;
    font-family: Copperplate-light;
    transition: transform .2s; /* Animation */
    box-shadow: 0 0 3px #333;
    text-align: center;
    margin: 25px 0 0 0;
    transition: transform .2s; /* Animation */
}

#team-member-button:hover {
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    color: white;
}

#team-member-button:active {
    color: goldenrod;
}


@media (max-width: 1300px){
    .about-columns{
        margin-top: 50px !important;
    }
    .about-column {
        padding: 20px;
        flex-basis: 100%;
        max-width: 1000px;
    }
    .about-column-title{
        flex-basis: 75px;
        flex-basis: auto !important;
    }
}

@media (min-width: 1301px) {
    .about-column-title {
        flex-basis: 150px !important;
    }
    

}

@media (max-width: 1650px) {
    .about-columns {
        margin-top: 50px;
    }

}


@media (max-width: 650px) {
    #sanjay {
        height: 300px
    }
    
    #sam {
        height: 300px
    }

    .leader{
        margin: 40px 10px;
    }

    .leader h2 {
        font-size: 2.3em;    
    }
    
    .leader h3 {
        font-size: 1.1em;
    }

    .leader-section h1{
        font-size: 3.3em;
    }
    
    .about-column-title {
        font-size: 0.9em;
    }

    .about-column div p {
        font-size: 1.2em;
    }
    .about-columns{
        margin: 0 25px 50px 25px !important;
    }
    
    .about-columns-section {
        margin: 50px 0 0 0;
    }

    .leader-section {
        padding: 10px 20px 50px 20px;
    }
}








/*
.team {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 50px 20px;
}

.team h1 {
    align-self: center;
    font-weight: 500;
    text-shadow: -1px -1px 0 black, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-size: 4em;
    margin: 0;
    font-family: Copperplate;
    color: goldenrod;
}

.team-member {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-style: double;
    padding: 20px;
    margin: 0 0 15px 0;
    border-color: goldenrod;
    background: white;
}

.team-member-text {
    max-width: 1000px;
    flex-grow: 1;
    margin: auto;
    flex-basis: 800px;
    padding: 25px 20px;
}

.team-member-text h2 {
    font-size: 2.8em;
    text-align: center;
    font-weight: 500;
    margin: 0 0 5px 0;
    font-family: Copperplate;
}

.team-member-text h3 {
    margin: 0 0 20px 0;
    font-size: 1.2em;
    text-align: center;
    font-weight: 500;
    font-family: Copperplate-light;
}


.team-member-text p {
    font-size: 1.2em;
    text-align: left;
}



.team-member img {
    object-fit: contain;
    height: 300px;
    width: 400px;
    border-radius: 50px;
}

*/