

.team-member-main{
}

.team-member {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    padding: 100px 0;
    margin: 100px 0 0 0;
}


.team-member-img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    flex-basis: 30%;
    margin: 20px 0 0 0;
}



.team-member img {
    width: 100%;
    border-radius: 25%;
    object-fit: contain;
    max-width: 1000px;
    max-height: 700px;

    pointer-events: none;
}


.team-member-text {
    flex-basis: 50%;
    margin: 0 30px 0 0;
    max-width: 1000px;
    padding: 0 25px 25px 25px;
}

.team-member-text h2 {
    font-size: 3.9em;
    margin: 0;
    font-family: Copperplate;
    color: #4ba9c8;
}
/* text-shadow: -1px -1px 0 black, 2px -1px 0 black, -1px 1px 0 black, 2px 2px 0 black;
 */

.team-member-text h3 {
    font-size: 2em;
    font-family: Copperplate-light;
    margin: 0 0 25px 0;
}

.team-member-text p {
    font-size: 1.6em;
    font-family: Monserrat;
    margin: 0 0 25px 0;
}


@media (max-width: 1200px){
    .team-member{
        justify-content: center;
    }
    .team-member-img-wrapper {
        flex-direction: row;
        flex-basis: 40%;
        margin: 0 0 50px 0;
    }  
    .team-member-text {
        flex-basis: 100%;
        margin: 0 0 0 0;
    } 

    .team-member-text h2, .team-member-text h3 {
        text-align: center;
    } 
     
}

@media (max-width: 725px){
    .team-member-img-wrapper {
        flex-basis: 75%;
        margin-bottom: 10px;
    }  

    .team-member {
        padding: 25px 25px;
    }
      
}


@media (max-width: 400px){
    .team-member-img-wrapper {
        flex-basis: 100%;
        margin-bottom: 0px;
        padding: 0 10px;
    }  

    .team-member {
        padding: 0 0 !important;
    }

    .team-member-text {
        padding: 0 25px 50px 25px;
    }
      
}

@media(min-width: 2000px){
    .team-member{
        justify-content: center;
    }
}