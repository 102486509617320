.investors-container {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    justify-content: center;
    align-items: center;
    background: white;
}

.investors-container h1 {
    font-size: 3em;
}

.investors-wrapper {
    display: flex;
    flex-direction: row;
}

.investors {
    margin: 50px 15px 0 15px;
    display: flex;
    flex-shrink: 2;
    flex-direction: column;
    flex-basis: 70%;
}

.investors-sidebar {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-basis: 30%;
    flex-shrink: 1;
    background:#F0F0F0;
    padding: 115px 1% 50px 1%;
}

.investors-sidebar img{
    width: 95%;
    margin: 0 0 25px 0;
}

.investors-sidebar p{
    width: 95%;
    margin: 0 0 25px 0;
    text-align: left;
    font-size: 1.4em;
    font-family: Copperplate-light;
    color: #2e7e98;
}



.investors-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
}

.investors-summary {
    margin: 0;
    padding: 0 10% 80px 10%;
    max-width: 1500px;
    font-size: 1.5em;
    font-family: Copperplate-light;
    max-width: 1500px;
    text-align: center;
}

.investors-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    padding: 0 0 20px 0;
    margin: auto;
}

.investors-data>p{
    align-self: flex-end;
    justify-self: flex-end;
    font-size: 1.1em;
    padding: 0 75px 25px 75px;
}



.investors-data b{
    font-size: 1.3em;
    font-weight: 600;
    font-family: Copperplate-light;
}

.pdf-section{
    /* background-color: #F0F0F0; */
    margin-top: 25px;
    width: 100%;
    border-top: 1px solid #E8E8E8;
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 50px 50px 0 50px;

}

.pdf-container{
    
}

.pdf-document {
    box-shadow: 0 0 4px #333; 
}

.pdf-nagivation-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    padding: 20px 0;
}

#pdfWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0; 
}

.pdf-nav-controls {
    display: flex;
    flex-direction: row;    
    align-items: center;
    justify-content: center;
    /* background-color:#F0F0F0; */
    border-radius: 5px;
    border: 1px solid gray;

    box-shadow: 0 0 4px #333;
}

.pdf-nav-controls>p{
    white-space: nowrap;
    padding-bottom: 0;
    align-self: center;
    margin: 0 5px;
    font-family: Copperplate;
    font-size: 1.2em;
    
}

.pdf-button {
    background-color: white;
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 0 0;
    cursor: pointer;

    border-radius: 2px;
    border: 1px solid gray;
}

.pdf-button:hover {
    background-color: gainsboro;
    /* border-radius: 5px;
    border: 1px solid gray; */
}

.pdf-nagivation-container:hover {
}







@media(max-width: 1000px) {
    .investors-wrapper {
        flex-wrap: wrap-reverse;
    }
    .investors {
        flex-basis: 100%;
    }
    
    .investors-sidebar {
        flex-basis: 100%;
        padding-top: 50px;
    }

    .investors-sidebar img {
        width: 75%;
    }

    .investors-sidebar p {
        margin-bottom: 0;
        padding: 0 10%;
        font-size: 1.3em;
    }
}


@media(max-width: 500px) {
    .investors{
        margin-top: 5px !important;
    }
    .investors-sidebar p {
        margin-bottom: 0;
        padding: 0 10%;
        font-size: 1.1em;
    }

    .investors-summary {
        font-size: 1.2em;
        padding: 0 5% 100px 5%;
    }

    .investors-data{
        padding: 0 0px 50px 0px;
    }

    .investors-data>p{
        padding: 0 25px 25px 25px;
    }

    .investors-sidebar p {
        font-size: 1.1em;
    }
    .pdf-section{
        padding-left: 10px;
        padding-right: 10px;
    }
}