.cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
}


.cover p {
    text-align: center;
    max-width: 800px;
    font-size: 1.3em;
    color: white;
}

.cover h2 {
    font-size: 6.5em;
    text-align: center;
    text-shadow: -1px -1px 0 black, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin: 0;
    font-family: Copperplate;
    color: white;
}



.image-banner {
    margin-top: 100px;
    height: 50vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: black;
    position: relative;
    object-fit: cover;

}
  



.image-banner p {
    font-size: 1.5em;
    padding: 0 0px;
}




/*
#b723ff
*/
.purple {
    --base:              #4ba9c8;
    --bg-blend:          multiply;
    --blur:              0px;
    --fg-blend:          lighten;
    --foreground:        #150260;
    --opacity:           1;
    --spacing:           0px;
    background-color:    var(--base);
    display:             flex;
    flex:                1 1 100%;
    height:              100%;
    overflow:            hidden;
    padding:             var(--spacing);
    position:            relative;
  }
  
  .purple img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    flex:                1 0 100%;
    height:              100%;
    max-width:           100%;
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
    position:            relative;
    width:               100%;
  }
  
  .purple::before {
    background-color:    var(--foreground);
    bottom:              0;
    content:             '';
    height:              100%;
    left:                0;
    mix-blend-mode:      var(--fg-blend);
    position:            absolute;
    right:               0;
    top:                 0;
    width:               100%;
    z-index:             1;
  }


  
  .blue {
    --base:              #fcf7f6;
    --bg-blend:          unset;
    --blur:              0px;
    --fg-blend:          lighten;
    --foreground:        #000000;
    --opacity:           1;
    --spacing:           0px;
    background-color:    var(--base);
    display:             flex;
    flex:                1 1 100%;
    height:              100%;
    overflow:            hidden;
    padding:             var(--spacing);
    position:            relative;
  }
  
  .blue img {
    filter:              grayscale(100%) contrast(1) blur(var(--blur));
    flex:                1 0 100%;
    height:              100%;
    max-width:           100%;
    mix-blend-mode:      var(--bg-blend);
    object-fit:          cover;
    opacity:             var(--opacity);
    position:            relative;
    width:               100%;
  }
  
  .blue::before {
    background-color:    var(--foreground);
    bottom:              0;
    content:             '';
    height:              100%;
    left:                0;
    mix-blend-mode:      var(--fg-blend);
    position:            absolute;
    right:               0;
    top:                 0;
    width:               100%;
    z-index:             1;
  }
  

  @media (max-width: 588px) {
    .cover h2 {
       font-size: 4em;
    }
    
  }

  @media (max-width: 400px) {
    .cover h2 {
       font-size: 3em;
    }
    
  }




  @media (max-width: 900px) {
    .image-banner {
      height: 50vh !important;
    }
    .about-banner-top {
        display: none !important;
    }
    .down-icon {
      display: none;
    }
  }



