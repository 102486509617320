.App {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden; 
}

.anchor-link { 
    padding-top: 100px !important;
    margin-top: -100px !important;
}
