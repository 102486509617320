.investment-criteria-container {
    flex-direction: column;
    padding: 50px 20px 50px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.investment-criteria-container h2 {
    margin: 0;
    padding-bottom: 0px;
    font-size: 2.8em;
    font-family: Monserrat;
    font-weight: 500;
}


.investment-criteria {
    margin: 20px 0 25px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.criteria {
    flex-basis: 100%;
    height: 240px;
    margin: 10px;
    position: relative;
    background: black;
}

.criteria:hover {
    background: #4ba9c8;
}

.criteria img {

    object-fit: cover;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    opacity: 0.5;
}

.criteria-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.criteria-text {
    text-align: center;
    color: white;
    font-size: 3.3em;
    font-family: Copperplate;
}




.prop-name {
    font-family: Monserrat;
    font-size: 1.5em;
    font-weight: 500;
}

.prop-location {
    font-size: 1em;
    color: #585858;
}


.criteria:hover img {
}
  
  .criteria .criteria-overlay {
    opacity: 1;
  }
  


.text {
    text-align: left;
}

.link {
    color: rgb(20, 82, 207);
    text-decoration: none;
}

.link:hover {
    color: goldenrod;
    text-decoration: none;
  }
.link:active {
    color: goldenrod;
    text-decoration: none;
  }



@media (max-width: 1250px) {
   
    
}
